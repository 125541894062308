import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './pages/template/header/header.component';
import { AccueilComponent } from './pages/template/accueil/accueil.component';
import { AproposComponent } from './pages/template/apropos/apropos.component';
import { ServicesComponent } from './pages/template/services/services.component';
import { TeamComponent } from './pages/template/team/team.component';
import { ContactComponent } from './pages/template/contact/contact.component';
import { FootComponent } from './pages/template/foot/foot.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AccueilComponent,
    AproposComponent,
    ServicesComponent,
    TeamComponent,
    ContactComponent,
    FootComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
